import Java from "../icons/java.svg"
import CSharp from "../icons/csharp.svg"
import JS from "../icons/js.svg"
import PHP from "../icons/php2.svg"
import Python from "../icons/python.svg"
import ReactIcon from "../icons/react.svg"
import VS from "../icons/vs.svg"
import VCS from "../icons/vscode.svg"
import Blender from "../icons/Blender.svg"
import CPlus from "../icons/cpp.svg"
import CSS from "../icons/css.svg"
import HTML from "../icons/html.svg"
import Figma from "../icons/figma.svg"
import Git from "../icons/git.svg"
import NodeJs from "../icons/nodejs.svg"
import Photoshop from "../icons/Photoshop.svg"
import React, { useEffect, useState } from "react"
import Spring from "../icons/spring.svg"
import dotnet from "../icons/dotnet.svg"
import aspnet from "../icons/asp.svg"
import swift from "../icons/swift.svg"
import android from "../icons/android.svg"
import SkillCard from "./skillCard"
import kotlin from "../icons/kotlin.svg"
import sql from "../icons/sql.svg"
import sqlite from "../icons/sqlite.svg"
import mongo from "../icons/mongodb.svg"
import laravel from "../icons/laravel.svg"
function Skills(){

    return(
            <div className="gap-8 justify-items-center place-content-center overflow-hidden mb-8 mt-8 flex lg:flex-row md:flex-col sm:flex-col flex-col slidedown">
            
            <div className="w-full flex flex-col flex flex-row">
                <div className="h-1/2">
                    <p className="text-center">Languages</p>
                    <div className="grid grid-cols-4 mx-4 gap-2 justify-items-center place-content-center">
                    <SkillCard
                        image={Java}
                        skillName="Java"/>
                        <SkillCard
                        image={CSharp}
                        skillName="C#"/>
                        <SkillCard
                        image={JS}
                        skillName="Javascript"/>
                        <SkillCard
                        image={PHP}
                        skillName="PHP"/>
                        <SkillCard
                        image={Python}
                        skillName="Python"/>
                        <SkillCard
                        image={CPlus}
                        skillName="C++"/>
                        <SkillCard
                        image={HTML}
                        skillName="HTML"/>
                        <SkillCard
                        image={CSS}
                        skillName="CSS"/>
                        <SkillCard
                        image={swift}
                        skillName="Swift"/>
                        <SkillCard
                        image={kotlin}
                        skillName="Kotlin"/>
                    </div>
                </div>
        
                <div className="h-1/2 mt-8">
                    <p className="text-center">Tools & Platforms</p>
                    <div className="grid grid-cols-4 mx-4 gap-2 justify-items-center place-content-center">
                    <SkillCard
                        image={VS}
                        skillName="Visual Studio"/>
                        <SkillCard
                        image={VCS}
                        skillName="Visual Studio Code"/>
                        <SkillCard
                        image={Blender}
                        skillName="Blender"/>
                        <SkillCard
                        image={Figma}
                        skillName="Figma"/>
                        <SkillCard
                        image={Git}
                        skillName="Git"/>
                        <SkillCard
                        image={Photoshop}
                        skillName="Photoshop"/>
                    </div>
                </div>
            </div>
        
            <div className="w-full">
                <div className="h-1/2">
                    <p className="text-center">Frameworks & Technologies</p>
                    <div className="grid grid-cols-4 mx-4 gap-2 justify-items-center place-content-center">
                        <SkillCard
                        image={ReactIcon}
                        skillName="React"/>
                        <SkillCard
                        image={NodeJs}
                        skillName="NodeJS"/>
                        <SkillCard
                        image={Spring}
                        skillName="Spring Boot"/>
                        <SkillCard
                        image={aspnet}
                        skillName="ASP.NET"/>
                        <SkillCard
                        image={dotnet}
                        skillName=".NET Framework"/>
                        <SkillCard
                        image={android}
                        skillName="Android"/>
                        <SkillCard
                        image={laravel}
                        skillName="Laravel"/>
                    </div>
                </div>
        
                <div className="h-1/2 mt-8">
                    <p className="text-center">Databases</p>
                    <div className="grid grid-cols-4 mx-4 gap-2 justify-items-center place-content-center">
                    <SkillCard
                        image={sql}
                        skillName="SQL / MySQL"/>
                        <SkillCard
                        image={sqlite}
                        skillName="SQLite"/>
                        <SkillCard
                        image={mongo}
                        skillName="MongoDB"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Skills;