import { useEffect } from "react";

function BusinessNavBar({setPageIndex, businessPageIndex, setBusinessIndex}){
    useEffect(()=>{
        switch(businessPageIndex) {
            case 0: 
            document.getElementById('businessAbout').className = 'underline cursor-pointer hover:text-teal-200'
            document.getElementById('businessProjects').className = 'cursor-pointer hover:text-teal-200'
            document.getElementById('businessSkills').className = 'cursor-pointer hover:text-teal-200'
            break

            case 1:
            document.getElementById('businessAbout').className = 'cursor-pointer hover:text-teal-200'
            document.getElementById('businessProjects').className = 'underline cursor-pointer hover:text-teal-200'
            document.getElementById('businessSkills').className = 'cursor-pointer hover:text-teal-200'
            break

            case 2:
            document.getElementById('businessAbout').className = 'cursor-pointer hover:text-teal-200'
            document.getElementById('businessProjects').className = 'cursor-pointer hover:text-teal-200'
            document.getElementById('businessSkills').className = 'underline cursor-pointer hover:text-teal-200'
            break

        }
    },[businessPageIndex])

    return(
            <div className="flex justify-between sm:mx-10 md:mx-0 pt-4">
                {/* <a onClick={()=>{setPageIndex(1)}} className="cursor-pointer hover:text-teal-200">Home</a> */}
                <a id="businessAbout" onClick={()=>{setBusinessIndex(0)}}>About</a>
                <a id="businessProjects" onClick={()=>{setBusinessIndex(1)}}>Projects</a>
                <a id="businessSkills" onClick={()=>{setBusinessIndex(2)}}>Skills</a>
                <a href="mailto:nick@mazzafamily.ca" className="cursor-pointer hover:text-teal-200">Contact</a>
            </div>
    )
}
export default BusinessNavBar;