function Home({setPageIndex, swapHomePage}){
    return(
        <div id="homePage" className="flex grid grid-cols-1 content-center justify-center h-full text-white text-2xl slidedown">
        <p>Welcome to my portfolio,<br/></p>
        <div className="flex flex-row gap-4 ml-4 mt-4">
          <div className="bubble-button cursor-pointer" onClick={swapHomePage}>
            Continue
          </div>

          {/* <div className="bubble-button cursor-not-allowed" onClick={()=>{
            //setPageIndex(2);
          }}>
            Personal
          </div>

          <div className="bubble-button cursor-not-allowed" onClick={()=>{
           // setPageIndex(3);
          }}>
            Other
          </div> */}
        </div>
      </div>
    )
}
export default Home;