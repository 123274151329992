import { useEffect, useState } from "react";
import github from "../icons/github.svg";
import Pdf from '../documents/NicholasMazzaFullstack.pdf';
import linkedin from "../icons/linkedin.svg"

function Footer() {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  const [translateHeight, setTranslateHeight] = useState(0);


  return (
    <div className="flex justify-between gap-4 mt-auto py-4 lg:mx-60 md:mx-40 sm:mx-10">
      <div className="flex gap-4">
      <div style={{ position: 'relative' }}>
        <div id="google_translate_element" style={{ position: 'relative', opacity: 0 }}></div>
        <button style={{ position: 'absolute', left: 0, top: 0, zIndex: 1, pointerEvents: 'none', color: 'white'}}>Translate</button>
      </div>
      <a href={Pdf} target="_blank" className="text-white">My Resume</a>
      </div>

      <div className="flex gap-4 mr-4">
        <a href='https://www.linkedin.com/in/nicholas-mazza-a5944a263/' className="text-white">
          <img src={linkedin} className="h-12 white-svg" alt="LinkedIn Logo" />
        </a>
        <a href='https://github.com/Nmazza1' className="text-white">
          <img src={github} className='h-12 white-svg' alt="GitHub Logo" />
        </a>
      </div>

      
    </div>
  );
}

export default Footer;
