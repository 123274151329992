import { useState } from "react";
import BusinessNavBar from "../components/BusinessNavBar";
import AboutMe from "../components/AboutMe"
import Projects from "../components/Projects";
import Skills from "../components/Skills";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

function BusinessPage({setPageIndex, swapHomePage}){
    const [businessPageIndex, setBusinessIndex] = useState(0)
    return(
            <div className="text-white text-xl slidein flex-1 lg:mx-60 md:mx-40 sm:mx-12 mx-5">
            <BusinessNavBar
            setPageIndex={setPageIndex}
            businessPageIndex={businessPageIndex}
            setBusinessIndex={setBusinessIndex}/>

            {
                businessPageIndex === 0 ? <AboutMe/> : <></>
            }
            {
                businessPageIndex === 1 ? <Projects/> : <></>
            }
            {
                businessPageIndex === 2 ? <Skills/> : <></>
            }
            {
                businessPageIndex === 3 ? <Contact/> : <></>
            }   
            </div>
    )
}

export default BusinessPage;