function Project({name, description, style, githublink, deployedLink}){
    return(
        <div className="border-2 border-miami-pinkbg rounded-3xl p-4 bg-miami-purple flex flex-col" style={{width: '100%'}}>
        <div className="flex flex-row justify-between gap-8 text-sm">
        <p className="">{name}</p>
        <p className="">{style}</p>
        </div>
        <div className="text-base my-4">
            <p>{description}</p>
        </div>
        <div className="flex flex-row justify-between gap-8 text-sm text-miami-pink">
            <div className="flex gap-8">
            {
                githublink? <a href={githublink}>Github Link</a> : <></>
            }
            {
                deployedLink? <a href={deployedLink}>Travel to site</a> : <></>
            }
            </div>
        </div>
    </div>
    )
}

export default Project;