
function Experience({name, responsibility, position, company_link, date, img}){
    return(
        <div className="border-2 border-miami-pinkbg rounded-3xl p-4 bg-miami-purple grid grid-cols-1 lg:grid-cols-12 content-center" style={{width: '100%'}}>
            <div className="col-span-5 md:col-span-5 flex items-center justify-center">
                <img src={img} className="rounded"/>
            </div>

            <div className="col-span-7 md:col-span-7 p-2">
                <div className="flex flex-row justify-between gap-8 text-sm">
                    <p className="">{name}</p>
                    <p className="">{position}</p>
                </div>

                <div className="text-base my-4">
                    <p>{responsibility}</p><br/>
                    <p>{date}</p>
                </div>
            
                <div className="flex flex-row justify-between gap-8 text-sm text-miami-pink">
                    <div className="flex gap-8">
                    {
                        company_link? <a href={company_link}>Company Link</a> : <></>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Experience;