// SkillCard.js

import React from "react";

function SkillCard({ image, skillName }) {
  return (
    <div className="flex flex-col items-center">
      <img
        src={image}
        alt={skillName}
        className="w-16 h-16 object-cover mb-2"
      />
      <p className="text-center text-base">{skillName}</p>
    </div>
  );
}

export default SkillCard;
