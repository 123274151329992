import Project from "./project";

function Projects(){
    return(
        <div className="flex flex-col gap-4 w-full h-full slideup mt-12 overflow-auto">
            <p>If you are intrested in seeing other projects from me that aren't listed, feel free to view my <a href="https://github.com/Nmazza1" className="text-miami-bluebg">Github Portfolio</a>.</p>
            <Project
            name="Halalbites"
            description="Full scaled and deployed Web-App for communities with dietary restrictions. Built with Spring Boot, React, Auth0 and MySQL. Deployed
            on Railway.app and Render."
            style="Full Stack Development"/>

            <Project
            name="Pathways EDU"
            description="Future Development Roadmap developed for graduating highschool students to find applicable courses filtered 
            by course credits. Built with Spring Boot, React and MySQL."
            style="Full Stack Development"
            githublink="https://github.com/Nmazza1/PathwaysEdu-BackendAPI"/>

            <Project
            name="Printing Prodecures"
            description="3D Printing Printing Planning application that saves model, printer and material information and estimated
            printing times. Built with Swift and Google Firecloud."
            style="iOS Development"
            githublink="https://github.com/Nmazza1/PrintingProcedures"/>

            <Project
            name="Raining PI's"
            description="Weather Monitoring and Forecasting sytem utilizing RESTful API services to control heatlamps and cooling systems."
            style="IoT Development"
            githublink="https://github.com/Nmazza1/WeatherMonitoringSystemBackend"/>

            

         </div>
    )
}

export default Projects;