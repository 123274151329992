import BlankPicture from "../Images/BlankProfileimage1.png"
import portrait from "../Images/photo1.jpg"
import axios from "axios";
import { useState, useEffect } from "react";
import Message from "./message";
import Experience from "./ExperienceCard";
import Delisoft from "../Images/delisoft.png"
function AboutMe(){
    
    const [messages, setMessages] = useState([]);
    const [responseMessage, setResponse] = useState("");
    const [content, setContent] = useState("");
    const [senderName, setName] = useState("");
    const [senderRelation, setRelation] = useState("");
    const [senderEmail, setEmail] = useState("");
    const [messageSent, setMessageStatus] = useState(false);

    useEffect(()=>{
        axios.get('https://personalportfoliosystem-production.up.railway.app/api/messages')
        .then((response)=>{
            setMessages(response.data)
        })
        .catch((error)=>{console.log(error)})
    })

    const submitMessage = () =>{
        if (!senderName || !senderEmail || !senderRelation || !content) {
            alert("Please fill in all the required fields.");
            return; 
        }
        setMessageStatus(true);
        const currentDate = new Date();
        var date = `${currentDate.getDate()} | ${currentDate.getMonth() + 1} | ${currentDate.getFullYear()}`
        axios.post('https://personalportfoliosystem-production.up.railway.app/api/messages',
        {
            content : content,
            senderRelation : senderRelation,
            senderName : senderName,
            timeOfMessage : date,
            senderEmail : senderEmail
        })
        .then((response)=>{
            if(response.status==200)
            {
                setResponse("Successfully received your comment, you'll be notified once it's accepted!")
                
            }else if(response.status==400){
                setResponse("Failed to send your message, please try again later.")
            }
        })
        .catch((error)=>{console.log(error)})
    }
    return(
            <div>
                <div className="flex sm:flex-col md:flex-col lg:flex-row flex-col content-center slidedown mt-12">
                        <div className="mx-auto">
                            <p className="mb-4">Welcome!</p>
                            <p className="">
                                My name is Nicholas, a recently graduated Computer Science student whos looking to explore the limits of Web Development Technologies
                                all the while fostering collaborative work environments and learning something new with every challenge faced! 
                            </p>

                            <p className="mt-4">My forward thinking mentality helps me critically analyze business requirements and turn them into clean,
                             scalable code ready for production.</p>

                             <p className="mt-4">I aspire to one day be the reliable shoulder I leaned on when learning to program for the next generation after me.</p>
                        </div>
        
                        <div className="flex justify-center items-center lg:mt-0 xl:mt-0 mt-10">
                            <img src={portrait} className="rotate-6 drop-shadow-2xl rounded-lg w-3/4"/>
                        </div>
                </div>

                <div className="mt-10">
                    <p>Relevant Experience</p>
                    <div className="flex flex-row gap-4 w-full slidedown mt-4">
                        <Experience
                            name="Delisoft"
                            responsibility="Responsibilities include analyzing, desiging and developing main systems for various client projects.
                             Technologies used include PHP, Laravel 10, Vue.js 3, and JQuery."
                            position="Full Stack PHP Developer"
                            company_link="https://delisoft.ca/"
                            date="March 2024 - Ongoing"
                            img={Delisoft}
                        />
                        
                    </div>
                </div>
                
                <p className="text-center mt-12 slidedown">Hear what others have to say</p>
                    <div className="flex flex-row gap-4 w-full slidedown mt-4 overflow-auto py-4 no-scrollbar slidedown">
                    {messages.length === 0 ? (
                        <p className="text-center text-base text-white">No messages available. Please come back later.</p>
                        ) : (
                            messages.map((message, index) => (
                                <Message
                                    key={index}
                                    content={message.content}
                                    senderName={message.senderName}
                                    senderRelation={message.senderRelation}
                                    timeOfMessage={message.timeOfMessage}
                                />
                            ))
                        )}
                    </div>
                <p className="text-center mt-12 slidedown">Interested in leaving a comment?</p>
                <div className="lg:mx-32 sm:mx-24 bg-miami-purple p-8 flex flex-col text-black">
                    <label htmlFor="yourName" className="block text-white mb-2">
                        Your Name:
                    </label>
                    <input
                        type="text"
                        id="yourName"
                        value={senderName}
                        onChange={(e) => setName(e.target.value)}
                        className="w-full p-2 mb-4"
                    />
        
                    <label htmlFor="yourName" className="block text-white mb-2">
                        Your Email:
                    </label>
                    <input
                        type="text"
                        id="yourEmail"
                        value={senderEmail}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full p-2 mb-4"
                    />
        
                    <label htmlFor="relation" className="block text-white mb-2">
                        Relation to Me:
                    </label>
                    <select
                        id="relation"
                        value={senderRelation}
                        onChange={(e) => setRelation(e.target.value)}
                        className="w-full p-2 mb-4"
                    >
                        <option value="">Select Relation</option>
                        <option value="Peer">Peer</option>
                        <option value="Coworker">Coworker</option>
                        <option value="Boss">Boss</option>
                        <option value="Professor">Professor</option>
                        <option value="Supervisor">Supervisor</option>
                    </select>
        
                    <label htmlFor="yourComment" className="block text-white mb-2">
                        Your Comment:
                    </label>
                    <textarea
                        id="yourComment"
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        rows="4" // You can adjust the number of rows displayed
                        className="w-full p-2 mb-4 resize-y" // Using `resize-y` for vertical resizing
                    />
        
                    {
                        !messageSent ? <button onClick={submitMessage} className="bg-blue-500 text-white p-2 rounded">
                        Submit
                    </button>
                    :
                    <p className="text-center text-base text-white">{responseMessage}</p>

                    }
                    
                </div>
            </div>
    )
}
export default AboutMe;