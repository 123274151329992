import './App.css';
import { useState } from 'react';
import BusinessPage from './pages/Business';
import Home from './pages/Home';
import Footer from './components/Footer';

const delay = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

function App() {

const swapHomePage = async event => {
  document.getElementById('homePage').className = 'flex grid grid-cols-1 content-center justify-center h-screen text-white text-2xl disappear'
  await delay(1000);
  setPageIndex(2)
};

  const [pageIndex, setPageIndex] = useState(1);
  return (
    <div className="font-jetbrains bg-miami-purple bg-gradient-to-br from-miami-pinkbg/30
     to-miami-bluebg/30 flex flex-col min-h-screen">
      
      {
        pageIndex === 1 ? 
      <div className='mx-60 grid flex flex-col min-h-screen slidein'>
      <Home
        setPageIndex={setPageIndex}
        swapHomePage={swapHomePage}/>
      </div>
      :
      <></>
      }

      {
        
         pageIndex === 2 ? <BusinessPage
         setPageIndex={setPageIndex}
         swapHomePage={swapHomePage}/> : <></>
      }

      {
         pageIndex === 3 ? <p>This page is still under development! Please come back later! </p> : <></>
      }

      {
         pageIndex === 4 ? <p>This page is still under development! Please come back later! </p> : <></>
      }
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link href="https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500;700&display=swap" rel="stylesheet"/>
      <Footer/>
      </div>

  );
}

export default App;
