function Message({content, senderName, senderRelation, timeOfMessage}){
    return(
        <>
        {
            window.outerWidth >= 768 ? 

            <div className="border-2 border-miami-pinkbg rounded-3xl bg-miami-purple flex flex-col p-4" style={{ width: '50%' }}>
            <div className="flex flex-row justify-between gap-8 text-sm">
            <p>{senderName}</p>
            <p>{senderRelation}</p>
            </div>
            
            <div className="text-sm">
                <p>{content}</p>
            </div>
            <p className="text-sm">{timeOfMessage}</p>
        </div>
            :

            <div className="border-2 border-miami-pinkbg rounded-3xl bg-miami-purple flex flex-col w-full p-4">
            <div className="flex flex-row justify-between gap-8 text-sm">
            <p>{senderName}</p>
            <p>{senderRelation}</p>
            </div>
            
            <div className="text-sm">
                <p>{content}</p>
            </div>
            <p className="text-sm">{timeOfMessage}</p>
        </div>

        }
        
        </>
    )
}

export default Message;